import { List, ListItemIcon, ListItemText } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackLink } from "../../components/core-sub/BackLink";
import { PickIcon, PickIconName } from "../../components/core-sub/PickIcon";
import { StockDisplay } from "../../components/core-sub/StockDisplay";
import { useCourse } from "./context";
import { ListItemButton } from "./list.item.button";

const icons: { [key: string]: PickIconName } = {
  lesson: "chalkboard",
  question: "list-ol",
};

export const ViewCourseSidebar = () => {
  const { courseId, id } = useParams();
  const { state } = useCourse();
  const nav = useNavigate();

  return (
    <React.Fragment>
      <BackLink to="/" divider />
      {state.data?.feature && (
        <StockDisplay {...state.data.feature} ratio={1 / 3} />
      )}
      <List>
        {state.data?.title && (
          <ListItemButton
            divider
            selected={!Boolean(id)}
            onClick={() => nav(`/course/${courseId}`)}
          >
            <ListItemText
              primary={state.data.title}
              secondary={state.data.teacher}
              primaryTypographyProps={{ variant: "h6" }}
              secondaryTypographyProps={{
                variant: "caption",
                color: Boolean(id) ? "textSecondary" : "inherit",
              }}
            />
          </ListItemButton>
        )}
        {state.data?.docs.map((doc) => (
          <ListItemButton
            divider
            key={doc.id}
            selected={doc.id === id}
            onClick={() => nav(`/course/${courseId}/${doc.type}/${doc.id}`)}
          >
            <ListItemIcon>
              <PickIcon icon={icons[doc.type] || "question"} />
            </ListItemIcon>
            <ListItemText
              primary={doc.title}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItemButton>
        ))}
      </List>
    </React.Fragment>
  );
};
