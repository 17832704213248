import { ListItemButton as LB, styled } from "@mui/material";

export const ListItemButton = styled(LB)(({ theme }) => ({
  "&.Mui-selected": {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "& .MuiListItemIcon-root": {
    fontSize: "2rem",
    color: "inherit",
    paddingRight: '0.5rem'
  },
}));
