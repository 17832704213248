import { Link } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCore } from "../../components/core-sub/context";
import { PageViewer } from "../../components/core-sub/PageViewer";
import { useCourse } from "./context";

export const ContentLesson = () => {
  const { t } = useCore();
  const { courseId, type, id } = useParams();
  const { state } = useCourse();
  const data = state.data?.docs.find((d) => d.id === id);

  useEffect(() => {
    if (state.data?.title && data?.title) {
      document.title = `${data.title} | ${state.data.title} | ${process.env.REACT_APP_SITE_NAME}`;
    }
  }, [state, data]);

  return type === "lesson" && data ? (
    <PageViewer
      data={data}
      noContainer
      breadcrumbs={[
        {
          label: "Home",
          component: <Link href="https://mek.network">{t("Home")}</Link>,
        },
        { label: state.data?.title, to: `/course/${courseId}` },
        { label: data.title },
      ]}
    />
  ) : null;
};
