import { IconButton, IconButtonProps, styled } from "@mui/material";
import { PickIcon } from "../../components/core-sub/PickIcon";

export const MoreButton = styled((props: IconButtonProps) => (
  <IconButton size="small" {...props}>
    <PickIcon icon={"ellipsis-v"} />
  </IconButton>
))<IconButtonProps>({
  fontSize: "1.5rem",
  color: "white",
  position: "absolute",
  top: 4,
  right: 4,
});
