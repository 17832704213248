import { User } from "firebase/auth";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { timestampToDate, arrayShuffle } from "../components/core-sub/func";
import { PageDocument } from "../components/core-sub/PageEdit";
import { QuizDocument } from "../components/core-sub/QuizEditor";
import { StockDisplayProps } from "../components/core-sub/StockDisplay";
import { db } from "./firebase";

export interface CourseDocument {
  datecreate: Timestamp;
  datemodified: Timestamp;
  id: string;
  title: string;
  type: "course";
  user: string;
  cover?: StockDisplayProps;
  feature?: StockDisplayProps;
  teacher?: string;
  desc?: string;
  category?: string;
  syllabus?: PageDocument;
  visibility?: "public" | "private";
  subscribe?: string[];
}

export interface LessonDocument {
  contents: any[];
  datecreate?: Timestamp;
  datemodified?: Timestamp;
  id: string;
  parent: string;
  prefix?: string;
  sort: number;
  title: string;
  type: string;
  user: string;
  visibility?: "public" | "private";
}
export interface LessonDocumentTimeConvert extends Omit<LessonDocument, "datecreate" | "datemodified"> {
  datecreate?: Date;
  datemodified?: Date;
}

class MainClass {
  protected prefix: string = `${process.env.REACT_APP_PREFIX}`;

  protected collection(path: string, ...pathSegments: string[]) {
    return collection(db, "clients", this.prefix, path, ...pathSegments);
  }

  protected doc(path: string, ...pathSegments: string[]) {
    return doc(db, "clients", this.prefix, path, ...pathSegments);
  }

  protected toDoc = (doc: QueryDocumentSnapshot<DocumentData>) => ({
    ...doc.data(),
    id: doc.id,
  });
}

export class MyCourse extends MainClass {
  constructor(protected user: User) {
    super();
  }

  watch(callback: (docs: CourseDocument[]) => void) {
    return onSnapshot(
      query(
        this.collection("courses"),
        where("subscribe", "array-contains", this.user.uid)
      ),
      (snapshot) => {
        const docs = snapshot.docs.map(this.toDoc) as CourseDocument[];
        callback(docs);
      }
    );
  }

  async getPublic(): Promise<CourseDocument[]> {
    const snapshot = await getDocs(
      query(
        this.collection("courses"),
        where("type", "==", "course"),
        where("visibility", "==", "public")
      )
    );
    const docs = snapshot.docs.map(this.toDoc) as CourseDocument[];
    return docs;
  }

  async enroll(courseId: string): Promise<void> {
    await updateDoc(this.doc("courses", courseId), {
      subscribe: arrayUnion(this.user.uid),
    });
  }

  async unenroll(courseId: string): Promise<void> {
    await updateDoc(this.doc("courses", courseId), {
      subscribe: arrayRemove(this.user.uid),
    });
  }
}

export interface CourseDisplayDocument extends CourseDocument {
  docs: LessonDocumentTimeConvert[];
}
export class Courses extends MainClass {
  constructor(protected user: User) {
    super();
  }

  get(courseId: string): Promise<CourseDisplayDocument | null> {
    return new Promise(async (resolved) => {
      const courseSnap = await getDoc(this.doc("courses", courseId));
      if (courseSnap.exists() === false) {
        resolved(null);
      } else {
        const course = courseSnap.data() as CourseDocument;
        if (!Boolean(course.subscribe?.includes(this.user?.uid))) {
          resolved(null);
        } else {
          const docs = (
            await getDocs(
              query(this.collection("courses"), where("parent", "==", courseId))
            )
          ).docs
            .map((doc) => {
              const { datecreate, datemodified, ...newDoc } = this.toDoc(
                doc
              ) as LessonDocument;
              const converted: LessonDocumentTimeConvert = {
                ...newDoc,
                datecreate: datecreate
                  ? timestampToDate(datecreate)
                  : new Date(),
                datemodified: datemodified
                  ? timestampToDate(datemodified)
                  : new Date(),
              };
              return converted
            })
            .sort((a, b) => a.sort - b.sort);
          resolved({ ...course, docs });
        }
      }
    });
  }
}

export interface questionbankForLessons {
  title: string;
  questionid: string;
  amount: string;
  point: number;
  fixnumber: number;
  type: string;
}

export interface ViewQuestionType extends questionbankForLessons {
  docs: (QuizDocument & { id: string })[];
}

export class Question extends MainClass {
  get(questionId: string): Promise<ViewQuestionType> {
    return new Promise(async (resolved) => {
      const snap = await getDoc(this.doc("courses", questionId));
      const data = snap.data() as questionbankForLessons;

      const snapquiz = await getDocs(
        query(
          this.collection("questions"),
          where("questionparent", "==", data.questionid)
        )
      );
      const docs = arrayShuffle(
        snapquiz.docs.map(
          (doc) =>
            this.toDoc(doc) as QuizDocument & {
              id: string;
            }
        )
      ).slice(0, parseInt(data.amount));

      resolved({ ...data, docs });
    });
  }
}
