import * as React from "react";
import { CourseDocument, MyCourse } from "../../controllers/course.ctrl";

export const HomeContext = React.createContext<{
  control: null | MyCourse;
  docs: CourseDocument[];
}>({
  control: null,
  docs: [],
});

export const useHome = () => React.useContext(HomeContext);
