import * as React from "react";
import {
  Button,
  Container,
  Link as MLink,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  styled,
  Typography,
} from "@mui/material";
import { MyCourse } from "../../controllers/course.ctrl";
import type { CourseDocument } from "../../controllers/course.ctrl";
import {
  CardActions,
  CardBody,
  CardContainer,
  CardContent,
  CardFeature,
  CardItem,
} from "../../components/card";
import { MoreButton } from "./more.button";
import { CourseList } from "./course.list";
import { HomeContext } from "./context";
import { useNavigate } from "react-router-dom";
import { ContentHeader } from "../../components/core-sub/ContentHeader";
import { useCore } from "../../components/core-sub/context";
import MainContainer from "../../components/core-sub/MainContainer";
import { PickIcon } from "../../components/core-sub/PickIcon";

export const genRandom = () => {
  const data: number[] = [];
  for (let i = 0; i < 2057; i++) {
    const val = Math.round(Math.random() * 20) === 1 ? 0 : 1;
    data.push(val);
  }
  return data.join("\n");
};

const CardButton = styled(Button)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  fontSize: "5rem",
});

export const ViewHome = () => {
  const { user, t } = useCore();
  const [control, setControl] = React.useState<null | MyCourse>(null);
  const [state, setState] = React.useState<{
    loading: boolean;
    docs: CourseDocument[];
  }>({
    loading: true,
    docs: [],
  });
  const [menu, setMenu] = React.useState<{
    anchorEl?: Element;
    id?: string;
  }>({});
  const nav = useNavigate();

  const handleCloseMenu = () => setMenu({});
  const handleUnenroll = async () => {
    if (menu?.id) {
      await control?.unenroll(menu.id);
      handleCloseMenu();
    }
  };

  React.useEffect(() => {
    if (user.loading === false && user.data) {
      const control = new MyCourse(user.data);
      setControl(control);
      return control.watch((docs) =>
        setState((s) => ({ ...s, docs, loading: false }))
      );
    }
    return () => {};
  }, [user]);

  return (
    <HomeContext.Provider value={{ control, docs: state.docs }}>
      <MainContainer loading={state.loading} signInOnly>
        <Container maxWidth={"lg"}>
          <ContentHeader
            breadcrumbs={[
              {
                label: t("Home"),
                component: (
                  <MLink href="https://mek.network">{t("Home")}</MLink>
                ),
              },
              { label: t("My Course") },
            ]}
            label={t("My Course")}
          />
          <CardContainer>
            {state.docs.map((doc) => (
              <CardItem key={doc.id}>
                <CardFeature {...doc.feature} />
                <CardBody>
                  <CardContent>
                    <Typography variant="h6">{doc.title}</Typography>
                    <Typography variant="body2" color="textSecondary" noWrap>
                      {doc.desc}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" noWrap>
                      {doc.teacher}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="outlined"
                      startIcon={
                      <PickIcon icon="book-alt" />
                    }
                      onClick={() => nav(`/course/${doc.id}`)}
                    >
                      {t("STUDY")}
                    </Button>
                  </CardActions>
                </CardBody>
                <MoreButton
                  onClick={({ currentTarget }) =>
                    setMenu({ anchorEl: currentTarget, id: doc.id })
                  }
                />
              </CardItem>
            ))}
            <CardItem>
              <CourseList>
                <CardButton>
                  <PickIcon icon={"plus"} />
                </CardButton>
              </CourseList>
            </CardItem>
          </CardContainer>
          <Menu
            keepMounted
            open={Boolean(menu.anchorEl)}
            anchorEl={menu.anchorEl}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <List>
              <ListItemButton onClick={handleUnenroll}>
                <ListItemText
                  primary="Unenroll"
                  primaryTypographyProps={{ color: "error" }}
                />
              </ListItemButton>
            </List>
          </Menu>
        </Container>
      </MainContainer>
    </HomeContext.Provider>
  );
};
