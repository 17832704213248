import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { app } from "./controllers/firebase";
import { ViewCourse } from "./views/course";
import { ViewHome } from "./views/home";
import { ViewQuestion } from "./views/question";
import logo from "./logo.svg";
import CoreProvider from "./components/core-sub/context";
import { AppMenu, getAppMenu } from "./components/core-sub/app.menu";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/course/:courseId/question/:questionId"
          element={<ViewQuestion />}
        />
        <Route path="/course/:courseId/:type/:id" element={<ViewCourse />} />
        <Route path="/course/:courseId" element={<ViewCourse />} />
        <Route path="/" element={<ViewHome />} />
      </Routes>
    </BrowserRouter>
  );
}

export default function Main() {
  const [appMenu, setAppMenu] = React.useState<AppMenu[]>([]);

  React.useEffect(() => {
    getAppMenu(app).then(setAppMenu)
  }, []);

  return (
    <CoreProvider firebaseApp={app} logo={logo} appMenu={appMenu}>
      <App />
    </CoreProvider>
  );
}
