import { Box, Button, Typography } from "@mui/material";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCore } from "../../components/core-sub/context";
import { quizAnswerCheck } from "../../components/core-sub/func";
import { PickIcon } from "../../components/core-sub/PickIcon";
import { QuizAnswer } from "../../components/core-sub/QuizAnswer";
import { ViewQuestionType } from "../../controllers/course.ctrl";
import { Answers } from "./stepper";

export const Solved = (props: {
  docs: ViewQuestionType["docs"];
  answers: Answers;
}) => {
  const { t } = useCore();
  const { courseId } = useParams();
  const nav = useNavigate();

  const scoreCalc = (): number =>
    props.docs.filter((doc) => quizAnswerCheck(doc, props.answers[doc.id]))
      .length;

  return (
    <Fragment>
      <Typography variant="h4" textAlign="center" paragraph>
        {t("Score")}: {scoreCalc()}/{props.docs.length}
      </Typography>
      <Box textAlign="center">
        <Button
          variant="outlined"
          startIcon={<PickIcon icon="chevron-left" />}
          onClick={() => nav(`/course/${courseId}`)}
        >
          Back
        </Button>
      </Box>
      {props.docs.map((doc, index) => (
        <Box mt={index > 0 ? 3 : 6} key={doc.id}>
          <QuizAnswer quiz={doc} answer={props.answers[doc.id]} />
        </Box>
      ))}
    </Fragment>
  );
};
