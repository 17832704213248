import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Slide,
  SlideProps,
} from "@mui/material";
import * as React from "react";
import { useCore } from "../../components/core-sub/context";
import { KuiButton } from "../../components/core-sub/KuiButton";
import { CourseDocument } from "../../controllers/course.ctrl";
import { useHome } from "./context";

export const CourseList = (props: { children: React.ReactElement }) => {
  const { t } = useCore()
  const { control, docs } = useHome();
  const [open, setOpen] = React.useState<boolean>(false);
  const [state, setState] = React.useState<{
    loading: boolean;
    docs: CourseDocument[];
  }>({
    loading: true,
    docs: [],
  });

  React.useEffect(() => {
    if (control && open) {
      control
        .getPublic()
        .then((docs) => setState((s) => ({ ...s, docs, loading: false })));
    } else {
      setState((s) => ({ ...s, loading: true, docs: [] }));
      return () => {};
    }
  }, [control, open]);

  return (
    <React.Fragment>
      {props.children &&
        React.cloneElement(props.children, {
          onClick: () => setOpen(true),
        })}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "down" } as SlideProps}
      >
        <DialogTitle>{t("Course")}</DialogTitle>
        <DialogContent>
          <List dense>
            <Divider />
            {state.loading && (
              <ListItem divider>
                <ListItemAvatar>
                  <Skeleton variant="rectangular" width={48} height={48} />
                </ListItemAvatar>
                <ListItemText
                  primary={<Skeleton width={"50%"} />}
                  secondary={<Skeleton width={"35%"} />}
                />
              </ListItem>
            )}
            {state.docs.map((doc) => (
              <ListItemButton divider key={doc.id}>
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    src={
                      doc.feature?.image?._id
                        ? `https://s1.phra.in:8086/file/id/${doc.feature.image._id}/thumbnail`
                        : undefined
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={doc.title}
                  secondary={doc.teacher}
                  primaryTypographyProps={{ variant: "body1" }}
                  secondaryTypographyProps={{ variant: "caption" }}
                />
                <ListItemSecondaryAction>
                  {Boolean(docs.find((d) => d.id === doc.id)) ? (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => control?.unenroll(doc.id)}
                    >
                      {t("Unenroll")}
                    </Button>
                  ) : (
                    <Button
                    variant="outlined"
                    onClick={() => control?.enroll(doc.id)}
                    >
                      {t("Enroll")}
                    </Button>
                  )}
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <KuiButton tx="close" onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
