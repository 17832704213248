import * as React from "react";
import { useParams } from "react-router-dom";
import { CourseContext, stateType } from "./context";
import { Courses } from "../../controllers/course.ctrl";
import { ViewCourseSidebar } from "./sidebar";
import { ContentLesson } from "./content.lesson";
import { ContentSyllabus } from "./content.syllabus";
import { useCore } from "../../components/core-sub/context";
import MainContainer from "../../components/core-sub/MainContainer";

export const ViewCourse = () => {
  const { user } = useCore();
  const { courseId } = useParams();
  const [state, setState] = React.useState<stateType>({ loading: true });

  React.useEffect(() => {
    if (user.loading === false && courseId) {
      if (user.data) {
        const course = new Courses(user.data);
        course.get(courseId).then((data) => {
          if (data) {
            setState({ loading: false, data, restrict: false });
          } else {
            setState({ loading: false, restrict: true });
          }
        });
      } else {
        setState({ loading: false, restrict: true });
      }
    }
  }, [user, courseId]);

  return (
    <CourseContext.Provider value={{ state, setState }}>
      <MainContainer
        dense
        loading={state.loading}
        restrict={state.restrict}
        signInOnly
        sidebar={<ViewCourseSidebar />}
      >
        <ContentSyllabus />
        <ContentLesson />
      </MainContainer>
    </CourseContext.Provider>
  );
};