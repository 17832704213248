import * as React from "react";
import { CourseDisplayDocument } from "../../controllers/course.ctrl";

export interface stateType {
  loading: boolean;
  data?: CourseDisplayDocument;
  restrict?: boolean;
}

export const CourseContext = React.createContext<{
  state: stateType;
  setState: React.Dispatch<React.SetStateAction<stateType>>;
}>({
  state: { loading: true },
  setState: () => {},
});

export const useCourse = () => React.useContext(CourseContext);
