import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'

export const firebaseConfig = {
  apiKey: 'AIzaSyDXDkEkLCv4y77jfXlQw4bgwUjLAidf3t4',
  authDomain: 'phra-in.firebaseapp.com',
  databaseURL:
    'https://phra-in-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'phra-in',
  storageBucket: 'phra-in.appspot.com',
  messagingSenderId: '557901104441',
  appId: '1:557901104441:web:c9c0affac3d4e4deb3d4e8',
  measurementId: 'G-JM03RN1CM2'
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const rt = getDatabase(app)
