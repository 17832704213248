import { Box, BoxProps, Grid, GridProps, Stack, styled } from "@mui/material";
import { BoxAbsolute, BoxPadding } from "../Box";
import { StockDisplayProps, StockDisplay } from "../core-sub/StockDisplay";

interface CardContainerProps extends GridProps {}
export const CardContainer = styled((props) => (
  <Grid container spacing={2} {...props} />
))<CardContainerProps>({});

const CardItemRoot = styled(BoxPadding)(({theme}) => ({
  border: `solid 1px ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.25s ease",
  "&:hover": {
    transform: "translateY(-8px)",
  },
}));
export const CardItem = (props: BoxProps) => {
  return (
    <Grid xs={12} sm={6} md={3} item>
      <CardItemRoot ratio={1} sx={{ borderRadius: 2, overflow: "hidden" }}>
        <BoxAbsolute display="flex" flexDirection="column" {...props} />
      </CardItemRoot>
    </Grid>
  );
};

export const CardFeature = (props: Omit<StockDisplayProps, "ratio">) => {
  return <StockDisplay {...props} ratio={1 / 3} />;
};

export const CardBody = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  flex: 1,
  overflow: "hidden",
}));

export const CardActions = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>
    <Stack direction="row" justifyContent="flex-end">
      {children}
    </Stack>
  </Box>
))(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  position: "absolute",
  left: 0,
  bottom: 0,
  width: "100%",
  boxSizing: "border-box",
}));
