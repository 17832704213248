import { Box, BoxProps, styled } from "@mui/material";

export interface BoxPaddingProps extends BoxProps {
  ratio: number;
}
export const BoxPadding = styled(Box, {
  shouldForwardProp: (prop) => prop !== "ratio",
})<BoxPaddingProps>(({ ratio }) => ({
  width: "100%",
  position: "relative",
  "&:before": {
    content: '""',
    display: "block",
    paddingTop: `calc(100% * ${ratio})`,
  },
}));

export const BoxAbsolute = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})