import { Link } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCore } from "../../components/core-sub/context";
import { PageDocument } from "../../components/core-sub/PageEdit";
import { PageViewer } from "../../components/core-sub/PageViewer";
import { useCourse } from "./context";

const genKey = (): string => Math.round(Math.random() * 1000000).toString();

export const ContentSyllabus = () => {
  const { t } = useCore();
  const { id } = useParams();
  const { state } = useCourse();
  const data: PageDocument = state.data?.syllabus
    ? {
        ...state.data.syllabus,
        title: state.data?.title,
      }
    : {
        title: state.data?.title,
        contents: [
          {
            type: "heading",
            heading: { variant: "h6", value: "Teacher" },
            key: genKey(),
          },
          {
            type: "paragraph",
            paragraph: { value: state.data?.teacher },
            mb: 3,
            key: genKey(),
          },
          {
            type: "heading",
            heading: { variant: "h6", value: "Description" },
            key: genKey(),
          },
          {
            type: "paragraph",
            paragraph: { value: state.data?.desc },
            mb: 3,
            key: genKey(),
          },
          {
            type: "heading",
            heading: { variant: "h6", value: "Subject" },
            key: genKey(),
          },
          {
            type: "paragraph",
            paragraph: { value: state.data?.category },
            mb: 3,
            key: genKey(),
          },
        ],
      };

  return !Boolean(id) && data ? (
    <PageViewer
      breadcrumbs={[
        {
          label: t("Home"),
          component: <Link href="https://mek.network">{t("Home")}</Link>,
        },
        { label: state.data?.title },
      ]}
      data={data}
      noContainer
    />
  ) : null;
};
